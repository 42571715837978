<template>
  <div>
    <h1>Welcome, {{ userInfo.first_name }}</h1>
    <div class="quick-actions">
      <h3>Quick Actions</h3>
      <div class="actions">
        <div
          class="actions__item"
          v-for="i in actions"
          :key="i.id"
          :style="{
            backgroundImage: `url(${require(`@/assets/images/dashboard/${i.image}`)}`,
          }"
        >
          <BaseButton
            class="button--black button--uppercase"
            :text="i.button"
            @click="selectAction(i)"
          ></BaseButton>
        </div>
      </div>
    </div>
    <Artist
      v-if="modal.artist"
      :modal="modal.artist"
      @hideModal="modal.artist = false"
      @createArtist="createArtist"
    ></Artist>
    <Art
      v-if="modal.art"
      :modal="modal.art"
      @hideModal="modal.art = false"
      @createArt="createArt"
    ></Art>
  </div>
</template>
<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import PopUp from "@/components/PopUp.vue";
import Artist from "@/views/admin/collection/artists/Artist.vue";
import Art from "@/views/admin/collection/artworks/Art.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { BaseButton, PopUp, Artist, Art },
  data() {
    return {
      actions: [
        { id: 1, button: "add art", action: "open", key: "art", image: "art.png" },
        { id: 2, button: "add creator", action: "open", key: "artist", image: "artist.png" },
        { id: 3, button: "website", action: "transfer", path: "/", image: "website.png" },
      ],
      modal: {
        art: false,
        artist: false,
      },
    };
  },
  computed: {
    ...mapGetters("user", {
      userInfo: "getUserInfo",
    }),
  },
  methods: {
    ...mapActions("general", ["apiDeleteUploadImage"]),
    ...mapActions("artists", ["apiPostArtist"]),
    ...mapActions("artworks", ["apiPostArt"]),
    selectAction(item) {
      switch (item.action) {
        case "open": {
          this.modal[item.key] = true;
          break;
        }
        case "transfer": {
          this.$router.push({ path: item.path });
          break;
        }
      }
    },
    async createArtist({ data, imageForRemove }) {
      let result = await this.apiPostArtist(data);
      if (result) {
        this.modal.artist = false;
        this.$swal({
          title: "Creator successfully created",
          confirmButtonText: "Ok",
        });
        if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        }
      }
    },
    async createArt({ data, imageForRemove }) {
      let result = await this.apiPostArt(data);
      if (result) {
        this.modal.art = false;
        this.$swal({
          title: "Artwork successfully created",
          confirmButtonText: "Ok",
        });
        if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-bottom: 4rem;
}
.quick-actions {
  padding: 3.25rem 4.4rem 1.6rem;
  border-radius: 16px;
  background-color: $white;
  border: 0.5px solid $lightGrey;
  h3 {
    width: 100%;
    @include type($fs: 2.1rem);
    color: $lightBlack;
    margin-bottom: 25px;
  }
}
.actions {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 2.8rem);
  margin-left: -1.4rem;

  &__item {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: calc((100% - 8.4rem) / 3);
    height: 49rem;
    margin: 0 1.4rem 2.8rem;
    padding: 17px 13px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    @media only screen and (max-width: $lg) {
      width: calc((100% - 8.4rem) / 2);
      height: 42rem;
      margin: 0 1.4rem 2.8rem;
    }
    @media only screen and (max-width: $md) {
      width: 100%;
      height: 38rem;
    }
  }
}
</style>